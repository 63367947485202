import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Stairs = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Изготовление интерьерных лестниц" />


        <section id="home" className="hero_full_screen hero_parallax text-center  parallax-stairs">


<div className="hero_parallax_inner">
<div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>Изготовление интерьерных лестниц</h1>
              <p>
                Лестница в интерьере – это не только практично, но и эстетично. Грамотно подобранная конструкция может украсить любое коммерческое или жилое помещение. Однако для этого нужно обращаться к проверенным специалистам. Компания «СК МАСТЕР» занимается изготовлением интерьерных лестниц на заказ. Мы используем самое современное оборудование и высококачественные материалы.
              </p>
              <hr />
            </div>
          </div>
        </div>
        </div>
</div>

</section>

    <section className="pb80 pt100 bg-dark uslugi stairs">
      <div className='container'>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Интерьерные лестницы от компании «МАСТЕР»
              </h2>
            <p>
              Обращаясь в нашу компанию, Вы выбираете не просто исполнителя, а целую команду хорошо себя зарекомендовавших специалистов. Все наши лестничные конструкции изготавливаются из первоклассных материалов и в полном соответствии с технологией.
            </p>
            <p>
              Кроме того, все интерьерные лестницы, создаваемые нашими мастерами, проходят строгий контроль качества. Благодаря этому, наши клиенты получают изделие «премиум»-качества по доступной цене.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Наши «плюсы»
              </h2>
            <p>
              Физические и юридические лица, которые уже успели заказать интерьерные лестницы в компании «СК МАСТЕР», выделяют следующие преимущества работы с нами:
              </p>
            <ul className="list-group checklist">
              <li>>лояльная ценовая политика. Этого нам удалось добиться, благодаря оптимизации своей работы, налаженным контактам с проверенными производителями и большому опыту;</li>
              <li>>соблюдение условий договора. Мы дорожим репутацией, поэтому соблюдаем сроки и не вносим изменений в цены по договорам без согласования с клиентом;</li>
              <li>>профессионализм. Мы используем лучшие технологии и оборудование, а все наши работы выполняются в полном соответствии с актуальными СНиПами и ГОСТами;</li>
              <li>>возможность решить любые задачи. Высокая квалификация и опыт наших специалистов позволяют им создавать интерьерные лестницы любых конфигураций.</li>
            </ul>
            <p>
              «СК МАСТЕР» выбирают частные застройщики и организации, ценящие надежность и качество без переплат.
            </p>
            <p>
              Для уточнения подробностей свяжитесь с нашими менеджерами любым удобным способом.
            </p>
          </div>
        </div>
      </div>
    </section>

  </Layout >
)

export default Stairs
